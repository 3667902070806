import React, { useEffect, useState } from "react";
import {
    ApolloClient,
    InMemoryCache,
    useQuery,
    gql
} from "@apollo/client";
import { css } from '@emotion/react'
import { Avatar, Skeleton, Container, Card, CardHeader, CardContent, ImageList, ImageListItem } from "@mui/material"

const containerCss = css`
    display: grid;
    gap: 24px;
    grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
`;
const LAUNCHES_PAST = gql`
    query launches {
  launchesPast(limit: 25) {
    mission_name
    launch_date_local
    launch_site {
      site_name_long
    }
    links {
      flickr_images
      mission_patch
    }
    details
  }
}
`;

const LaunchCard = ({ data }) => {
    const images = data.links.flickr_images.map((image) => {
        return (
            <ImageListItem>
                <img src={image} alt="launch" />
            </ImageListItem>
        )
    })
    if (!data.details) {
        return null;
    }
    return (
        <Card>
            <CardHeader
                avatar={<Avatar src={data.links.mission_patch} />}
                title={data.mission_name}
                subheader={data.launch_site.site_name_long} />
            <CardContent>
                {data.details}
            </CardContent>
            <CardContent>
                <ImageList gap={16} variant="masonry">
                    {images}
                </ImageList>
            </CardContent>
        </Card>
    )
}

const client = new ApolloClient({
    uri: 'https://api.spacex.land/graphql/',
    cache: new InMemoryCache()
});

const IndexPage = () => {
    const { loading, error, data } = useQuery(LAUNCHES_PAST, { client: client });
    const [output, setOutput] = useState([])
    useEffect(() => {
        setOutput(data?.launchesPast.map((launch) => (
            <LaunchCard data={launch} />
        )));
    }, [data]);
    if (loading) {
        return (
            <Container disableGutters css={containerCss}>
                <Skeleton height={1000} variant="rectangular" />
                <Skeleton height={1000} variant="rectangular" />
                <Skeleton height={1000} variant="rectangular" />
            </Container>
        )
    }
    if (error) {
        <p>Error</p>
    }
    return (
        <Container disableGutters css={containerCss}>
            {output}
        </Container>
    )
}

export default IndexPage;